<template>
	<div class="pb-4 pb-md-6">
		<div class="container" v-if="organization">
			<div class="row justify-content-center">
				<div class="col-12">
					<router-link
						class="btn btn-light"
						:to="{ name: 'Organizations'}"
					>
						<i class="far fa-chevron-left mr-3"></i> voltar
					</router-link>

					<div class="row my-4">
						<div class="col-12 col-md-auto text-center">
							<div class="d-inline-block mt-2 mb-4">							
								<button type="button" class="btn btn-light py-5">
									<div class="mx-5 my-3 font-48">
										{{ organization.name.substring(0, 1) }}
									</div>
								</button>							
							</div>
						</div>	
						<div class="col">
							<span>Organização</span>
							<h4 class="font-24 font-md-32 mb-4">{{ organization.name }}</h4>
							<div class="row">
								<div class="col-12 max-150">
									<small>SCHEMA</small>
								</div>
								<div class="col text-dark font-normal">
									{{ organization.instances[0].config.schema_name }}
								</div>
							</div>
							<div class="row">
								<div class="col-12 max-150">
									<small>{{organization.account_type == 2 ? `CNPJ` : `CPF`}}</small>
								</div>
								<div class="col text-dark font-normal">
									{{ organization.document_number }}
								</div>
							</div>
							<div class="row">
								<div class="col-12 max-150">
									<small>CRIAÇÃO</small>
								</div>
								<div class="col text-dark font-normal">
									{{ ParseDate(organization.created_at) }}
								</div>
							</div>
							<div class="row">
								<div class="col-12 max-150">
									<small>STATUS</small>
								</div>
								<div class="col text-dark font-normal">
									<div 
										class="badge"
										:class="{
											'badge-success': organization.status,
											'badge-danger': !organization.status
										}"
									>
										{{ organization.status ? 'ATIVA' : 'INATIVA'}}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="scroll-x scroll-transparent d-flex mx--5 mx-md-0">
						<ul class="nav nav-pills nav-theme w-md-100 justify-content-md-center my-4 my-md-5 px-5">
							<li v-if="HasRole(22)"
								class="nav-item">
								<a 
									
									class="nav-link pb-md-4 px-md-4"
									data-toggle="tab" 
									href="#info"
									ref="info"
								>	
									Dados
								</a>
							</li>
							<li v-if="HasRole(29) && 1 === 2"
								class="nav-item">
								<a 
									class="nav-link pb-md-4 px-md-4"
									data-toggle="tab" 
									href="#financial"
									ref="financial"
								>
									Financeiro
								</a>
							</li>
							<li v-if="HasRole(23)"
								class="nav-item">
								<a 
									
									class="nav-link pb-md-4 px-md-4"
									data-toggle="tab" 
									href="#employees"
									ref="employees"
								>
									Colaboradores
								</a>
							</li>
							<li v-if="HasRole(24)"
								class="nav-item">
								<a class="nav-link pb-md-4 px-md-4" data-toggle="tab" href="#profiles"
									ref="profiles">Perfis</a>
							</li>
							<li v-if="HasRole(25)"
								class="nav-item">
								<a class="nav-link pb-md-4 px-md-4" data-toggle="tab" href="#places"
									ref="places">Locais</a>
							</li>
							<li v-if="HasRole(26)"
								class="nav-item">
								<a class="nav-link pb-md-4 px-md-4" data-toggle="tab" href="#status"
									ref="status">Status</a>
							</li>
							<li v-if="HasRole(27)"
								class="nav-item">
								<a 
									class="nav-link pb-md-4 px-md-4"
									:class="{ 'active': HasRole(27) }"
									data-toggle="tab"
									href="#templates"
									ref="templates"
								>
									Templates
								</a>
							</li>
							<li v-if="HasRole(28)"
								class="nav-item">
								<a 
									class="nav-link px-2 pb-4"
									:class="{ 'active': HasRole(28) && !HasRole(27) }"
									data-toggle="tab"
									href="#advanced"
									ref="advanced"
								>
									Papel timbrado
								</a>
							</li>
						</ul>
					</div>

					<div class="tab-content">
						<div v-if="HasRole(22)"
							id="info" class="tab-pane">
							<OrganizationForm
								ref="form_organization"
								:is_registered="is_registered"
								:organization="organization"
								:organization_id="organization_id"
								@submit="SaveOrganizationData"
							/>
						</div>
						<div v-if="HasRole(29) && 1 === 2"
							id="financial" class="tab-pane">
							<Financial
								:is_registered="is_registered"
								:organization_id="organization_id"
								:providers="providers"
							/>
						</div>
						<div v-if="HasRole(23)"
							id="employees" class="tab-pane">
							<Employees 
								:permission="HasRole(23)"
								:providers="providers"
								:subscription="subscription"
								:organization_id="organization_id"
							/>
						</div>
						<div v-if="HasRole(24)"
							id="profiles" class="tab-pane">
							<Profiles
								:organization_id="organization_id"
							/>
						</div>
						<div v-if="HasRole(25)"
							id="places" class="tab-pane">
							<Places
								:organization_id="organization_id"
							/>
						</div>
						<div v-if="HasRole(26)"
							id="status" class="tab-pane">
							<div class="py-5 max-500 text-center mx-auto">
								<h3 class="font-24 mb-5">
									Você pode desativar e reativar temporariamente esta organização sempre que precisar.
								</h3>
								<button 
									class="btn"
									:class="{
										'btn-light-success': !organization.status,
										'btn-light-danger': organization.status
									}"

									@click="ToggleOrganizationStatus"
								>
									{{ organization.status ? `desativar` : `reativar`}} organização
								</button>
							</div>
						</div>
						<div v-if="HasRole(27)" id="templates" class="tab-pane"
							:class="{ 'active':  HasRole(27) }">
							<Templates
								:permission="HasRole(27)"
								:can_modify="HasRole(31)"
								:providers="providers"
								:organization="organization"
							/>
						</div>
						<div v-if="HasRole(28)" id="advanced" class="tab-pane"
							:class="{ 'active': !HasRole(27) && is_personal_org || !HasRole(27) && !is_personal_org}">
							<template v-if="organization.instances[0] && organization.instances[0].config">
								<Advanced
									:organization_id="organization_id"
									:logo="organization.instances[0].config.logo_url"
									:footer_sup="organization.instances[0].config.rodape_sup"
									:footer_inf="organization.instances[0].config.rodape_inf"
								/>
							</template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Helpers from '../../helpers'
	const helpers 		= new Helpers()

	const _ = require('lodash')

	import OrganizationForm from './components/Form'
	import Employees from './components/Employees'
	import Profiles from './components/Profiles'
	import Places from './components/Places'
	import Templates	from './components/Templates'
	import Advanced from './components/Advanced'
	import Financial from './components/Financial'

	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'Panel',
		props: 	{
			organization_id: 	{
				type: 		[String, Number],
				required: 	true,
				default: 	null
			}
		},
		components: 	{
			OrganizationForm,
			Employees,
			Profiles,
			Advanced,
			Financial,
			Places,
			Templates
		},
		data () {
			return {
				organization: 		null,
				providers: 			[],
				subscription:		null,
				user_roles:			[],
				native_profiles:	[],
				days_left:			0
			}
		},
		computed: {
			...mapGetters('auth', [
				'account', 'all_roles', 'requester_selected_id', 'is_registered'
			]),
			is_personal_org() {
				return this.organization.created_by === 2; // 1 = CRIADO PELO USUÁRIO | 2 = CRIADO PELO INSTANT
			}
		},
		watch: {
			all_roles: {
				handler(val)
				{
					if(this.organization_id) {
						this.user_roles = _.orderBy(val[`${this.organization_id}`], 'asc')
					}
				},
				immediate: true
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			...mapActions('auth', [
				'GetAuthAccount'
			]),
			GetOrganizationData(loading_spinner = true)
			{
				if(loading_spinner) this.StartLoading() 

				return window.api.call('post','/get-organization-management', {
						account_id: this.organization_id
					})
					.then(async ({data}) => {
						if(data.response) {
							this.organization 	= data.organization
							this.providers  	= data.organization.provider

							this.subscription	= data.organization.subscription
						} else {
							Swal.fire({
								title: 	'Eita!', 
								text: 	'Voce não possui permissão',
								icon: 	'error'
							})

							this.$router.push({ name: 'Organizations' })
						}
					})
					.finally(() => {
						if(loading_spinner) this.FinishLoading()
					})
			},
			ToggleOrganizationStatus()
			{
				if(this.organization_id === this.requester_selected_id) {
					Swal.fire({
						icon: 	'error',
						title: 	'Para alterar o status é necessário estar logado em outra conta!',
						toast: 	 true,
						position: 'top'
					})

					return
				}

				Swal.fire({
					icon: 		'warning',
					title:		'Atenção!',
					html:		`Se desativar a organização a seção de Financeiro e pagamentos do seu Plano serão impactados!<br>Realmente deseja ${this.organization.status ? 'des' : 're'}ativar?`,
					showCancelButton: true,
					confirmButtonColor: this.organization.status ? '#F64E60' : '#20C964',
					confirmButtonText:  `Sim, ${this.organization.status ? 'des' : 're'}ativar`,
					cancelButtonText:  'Cancelar'

				})
					.then((result) => {
						if(result.isConfirmed)
						{
							this.StartLoading()

							return window.api.call('post', '/toggle-organization-status',{
									account_id: 	this.organization_id
								})
								.then(({data}) => {
									if(data.response)
									{
										this.organization.status 	= data.status

										Swal.fire({
											title: 	'Status alterado com sucesso.',
											icon: 	'success',
											toast: 	 true,
											position: 'top'
										})

									}else{

										Swal.fire({ 
											title: 	'Opss...',
											text: 'Houve um erro ao concluir, contate o suporte.',
											icon: 'error'
										})
									}
								})
								.finally(() => {
									this.FinishLoading()
									this.GetAuthAccount()
								})
						}
					})
			},
			async SaveOrganizationData(form)
			{
				const vm = this

				vm.StartLoading()

				// vm.organization.phone_1 = vm.ParsePhone(vm.organization.phone_1)

				return window.api.call('post', '/save-organization', {
						account_id: 	vm.organization_id,
						...form
					})
					.then(async ({data}) => {

						if(data.response)
						{
							// await vm.IsRegistered()

							// if(!vm.is_registered)
							// {
							// 	await this.$refs.form_organization.SaveCostumer(form)
							// }

							Swal.fire({
								icon: 				'success',
								title: 				'Dados salvos com sucesso.',
								toast: 				true,
								timer: 				3000,
								position: 			'top'
							})

						}else{

							Swal.fire({
								title: 	'Opss...',
								text: 	'Houve um erro ao concluir, contate o suporte',
								icon: 	'error'
							})
						}
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			async IsRegistered()
			{
				const vm = this

				vm.StartLoading()

				return await window.api.call('post', '/gateway/is-registered', {
					organization_id: vm.organization_id
				})
					.then(({data}) => {
						vm.is_registered = data.response
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			ParseDate(date)
			{
				return helpers.ParseDate(date)
			},
			HasRole(role)
			{
				return this.user_roles && this.user_roles.indexOf(parseInt(role)) > -1
			},
			ToggleBlock(block)
			{
				const refs = Object.keys(this.$refs)

				for(let key in refs)
				{
					const tab = refs[key]

					if(tab != 'data')
					{
						if(block)
						{
							this.$refs[tab].classList.add('d-none')
						} else {
							this.$refs[tab].classList.remove('d-none')
						}
					}
				}
			},
		},
		beforeMount()
		{
			this.GetOrganizationData()
		}
	}
</script>

<style lang="css" scoped>

.swal-modal .swal-text {
    text-align: left !important;
}

</style>