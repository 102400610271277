<template>
	<div v-if="scheduling_data"
		class="col-12">
		<div class="border bg-light rounded-lg w-100">
			<div class="p-4">
				<div class="row">
					<div class="col-12" v-if="!print_component">
						<div class="row mb-3">
							<div class="col-3 col-md-auto mb-4 d-flex flex-column align-items-center">
								<img 
									:src="avatar"
									class="rounded-lg border border-secondary patient-avatar"
								>
							</div>
							<div class="col">
								<div class="row">
									<h4 class="m-0 mb-3 font-20">{{ patient.nome_social || patient.nome_paciente }}</h4>
								</div>
								<div class="row">
									<p class="mb-0">
										RG: <span :class="patient.rg ? 'font-weight-bold' : 'unknown'">{{patient.rg ? patient.rg.toUpperCase() : 'DESCONHECIDO' }} {{ patient.rg_uf ? patient.rg_uf.toUpperCase() : '' }}</span>
									</p>
									<p class="mb-0">
										CPF: <span :class="patient.cpf ? 'font-weight-bold' : 'unknown'">{{ patient.cpf ? patient.cpf.toUpperCase() : 'DESCONHECIDO' }}</span>
									</p>
									<p class="mb-0">
										SUS: <span :class="patient.sus ? 'font-weight-bold' : 'unknown'">{{ patient.sus ? patient.sus.toUpperCase() : 'DESCONHECIDO' }}</span>
									</p>
									<p class="mb-0">
										RH: <span :class="patient.rh ? 'font-weight-bold' : 'unknown'">{{ patient.rh ? patient.rh.toUpperCase() : 'DESCONHECIDO' }}</span>
									</p>
								</div>
								<div class="row my-2">
									<div class="px-0">
										DATA DE NASCIMENTO: <span :class="patient_birthdate ? 'font-weight-bold' : 'unknown'">{{ patient_birthdate }}</span>
										<span :class="patient_age ? 'font-weight-bold' : 'unknown'"> ({{ patient_age }})</span>
									</div>
									<div class="px-0">
										SEXO: <span :class="{ 'font-weight-bold' : patient.sexo }">{{ patient.sexo ? (patient.sexo === 'm' ? 'MASCULINO' : 'FEMININO') : 'DESCONHECIDO' }}</span>
									</div>
									<div class="px-0">
										TELEFONE: <b> {{ patient.telefone }} </b>
									</div>
									<div v-if="is_endoscopy_modality && patient.nome_mae" 
										class="px-0">
										NOME MÃE: <span class="mb-0"><b>{{ patient.nome_mae.toUpperCase() }}</b></span>
									</div>
								</div>
								<div class="row">
									<div class="px-0" v-if="scheduling_data.indicator_doctor">
										SOLICITANTE: <span class="mb-0"><b>{{ scheduling_data.indicator_doctor.name.toUpperCase() }}</b></span>
									</div>
									<div v-if="scheduling_data.partnership && scheduling_data.partnership.nome" 
										class="px-0">
										CONVÊNIO: <span class="mb-0"><b>{{ scheduling_data.partnership.nome.toUpperCase() }}</b></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12">
						<div class="pb-3">
							<div class="col-12 text-center">
								<h1>FICHA DE ATENDIMENTO</h1>
							</div>
							<div class="row mt-4">
								<div class="col-12 text-dark text-center">
									<h3>MODALIDADE: {{ scheduling_data.modality }}</h3>
								</div>
							</div>
							<hr/>
							<template>
								<div class="my-5">
									<div class="row" v-if="scheduling_data.agenda_continua">
										<div class="col-12 text-dark">
											<p class="mb-0 h4-size">
												DATA: <b>{{ parsed_date }}</b>
											</p>
										</div>
									</div>
									<div class="row" v-else-if="scheduling_data.encaixe">
										<div class="col-12 text-dark">
											<p class="mb-0 h4-size">
												DATA: <b>{{ parsed_date }} {{ parsed_time }} [ENCAIXE]</b>
											</p>
										</div>
									</div>
									<div class="row" v-else>
										<div class="col-12 text-dark">
											<p class="mb-0 h4-size">
												DATA: <b>{{ attendance_string }}</b>
											</p>
										</div>
									</div>
									<div class="row">
										<div class="col-12 text-dark">
											<p class="mb-0 h4-size">
												AGENDA: <b>{{ scheduling_data.schedule.nome }}</b>
											</p>
										</div>
									</div>
								</div>
							</template>
							<hr/>
							<div class="mt-5">
								<div class="row mb-3">
									<div 
										class="col-12 text-dark h4-size"
										v-for="(procedimento, index) in scheduling_data.procedimentos"
										:key="index"
									>
										<div v-if="procedimento">
											<p class="mb-0"><b>({{ index + 1 }}): {{ procedimento.nome }}</b></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>	
				</div>
			</div>
		</div>
		<div v-if="scheduling_data.procedimentos.length > 0 && has_instruction" class="border bg-light rounded-lg w-100 separate-cards">
			<div class="p-4">
				<div class="">
					<div class="mb-3">
						<div class="col-12 text-center mb-3">
							<h1>
								Instruções
							</h1>
						</div>
						<hr>
						<div
							class="row col-12 text-dark"
							v-for="(procedimento, index) in scheduling_data.procedimentos"
							:key="index"
						>
							<div v-if="procedimento && procedimento.preparo" class="col-12 p-0">
								<br v-if="index !== 0">
								<p class="mb-0 h4-size">
									{{ procedimento.preparo }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="scheduling_data.observacao !== null && journey_id"
			class="border bg-light rounded-lg w-100 separate-cards">
			<div class="p-4">
				<div class="row">
					<div class="col-12 text-dark">
						<p class="mb-0 h4-size">
							<b>Obs.: </b>{{ scheduling_data.observacao }}
						</p>
					</div>
				</div>
			</div>
		</div>
		<div v-else-if="journey"
			class="border bg-light rounded-lg w-100 separate-cards">
			<div class="p-4">
				<div class="form-group">
					<textarea
						v-debounce:500="DelayFillObs"
						class="form-control text-dark"
						placeholder="Observações"
						v-model="form.obs"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Swal from 'sweetalert2'
	import { mapGetters, mapActions } from 'vuex'
	import moment from 'moment'
	export default {

		name: 'SchedulingCard',
		props: 	{
			journey:		{
				type:			Object,
				default:		() => {}
			},
			journey_id: 	{
				type: 			[ String, Number ],
				default: 		null
			},
			print_component: {
				type:			Boolean,
				default:		false
			}
		},
		data () {
			return {
				scheduling_data: 		null,
				patient: 				null,
				defaultAvatar: 			window.defaultAvatar,
				form:					{
					obs:				''
				}
			}
		},
		computed: 	{
			...mapGetters('auth', [
				'requester_selected_id'
			]),
			avatar() {
				return this.patient && this.patient.avatar ? this.patient.avatar : this.defaultAvatar
			},
			doc_id() {
				if(this.patient.doc_id.tipo) {
					return `${this.patient.doc_id.tipo.toUpperCase()} ${this.patient.doc_id.numero.toUpperCase()}`
				} else {
					return `${this.patient.doc_id}`
				}
			},
			has_instruction() {
				const vm = this

				if(vm.scheduling_data && vm.scheduling_data?.procedimentos?.length) {
					return !!vm.scheduling_data.procedimentos.filter(p => p && !!p.preparo).length
				}

				return false
			},
			patient_age() {            		
	            if(this.patient && this.patient.data_nascimento) {
	                const dob 		= moment(this.patient.data_nascimento, 'YYYY-MM-DD')
	                const today 	= moment()

	                const years 	= today.diff(dob, 'year')
	                dob.add(years, 'years')

	                const months 	= today.diff(dob, 'months')
	                dob.add(months, 'months')
	                
	                const days 		= today.diff(dob, 'days')


	                let result 		= ''

	                if(years > 0) {
	                   result =  `${years} ANO${years > 1 ? 'S' : ''}`
	                } else if (years === 0 && months > 0) {
	                    result = `${months} MES${months > 1 ? 'ES' : ''} e ${days} DIA${days > 1 ? 'S' : ''}`
	                } else if (months === 0 && days > 0) {
	                    result = `${days} DIA${days > 1 ? 'S' : ''}`
	                } else {
	                    result = '0 DIAS'
	                }
	                
	                return result
	            } else {
	                return 'DESCONHECIDO'
	            }
			},
			attendance_time() {
				const vm = this

				return vm.scheduling_data.schedule.time_attendance || 0
			},
			attendance_string() {
				const vm = this

				if(!vm.scheduling_data.agenda_continua && !vm.scheduling_data.encaixe) {
					const attendance_time = moment(`${vm.scheduling_data.data} ${vm.scheduling_data.hora}`).subtract(vm.attendance_time, 'minutes')
					
					const time = {
						dia: moment(vm.scheduling_data.data).format('DD/MM/YYYY'),
						hora: moment(attendance_time).format('HH:mm')
					}
	
					if(time.dia && time.hora) {
						return `${time.dia} às ${time.hora}`
					}
				}

				return ''	
			},
			parsed_date() {
				const vm = this

				if(vm.scheduling_data.data) {
					return moment(vm.scheduling_data.data).format('DD/MM/YYYY')
				}

				return ''
			},
			parsed_time() {
				const vm = this

				if(vm.scheduling_data.data) {
					return moment(vm.scheduling_data.data).format('HH:mm')
				}

				return ''
			},
			is_endoscopy_modality() {
				const ENDOSCOPY_MODALITY_ID = 27

				return this.scheduling_data?.procedimentos?.filter(p => p.modality_id == ENDOSCOPY_MODALITY_ID).length > 0 ?? false
			},
			patient_birthdate() {
				return moment(this.patient.data_nascimento).format('DD/MM/YYYY')
			}
		},
		watch: {
			journey: {
				immediate: true,
				deep: true,
				handler(val) {
					if(val) {
						this.PopulateSchedulingDataAndPatient({ ...val })
					}
				}
			},
			journey_id: {
				async handler(val) {
					if(val) {
						await this.GetSchedulingData()
					}
				},
				immediate: true
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			PopulateSchedulingDataAndPatient(obj) {
	            const TIPO_ENCAIXE  = 3

				const vm = this

				vm.scheduling_data  = {}
				
				const journey   	= obj
				const { patient }   = journey

				if (patient.telefone)
				{
					if (Array.isArray(JSON.parse(patient.telefone)))
					{
						patient.telefone    = JSON.parse(patient.telefone)[0]
						vm.patient          = patient
					}
				}

				vm.scheduling_data.schedule  	 		= journey.schedulings[0].schedule
				vm.scheduling_data.observacao        	= journey.schedulings[0].obs || null
				vm.scheduling_data.agenda_continua   	= journey.schedulings[0].schedule.continua
				vm.scheduling_data.encaixe           	= journey.schedulings[0].tipo == TIPO_ENCAIXE
				vm.scheduling_data.indicator_doctor  	= journey.indicator_doctor || journey.schedulings[0].indicator_doctor || null

				vm.scheduling_data.module  			 	= journey.module
				vm.scheduling_data.modality  		 	= journey.modality
				vm.scheduling_data.partnership  	 	= journey.schedulings[0].partnerships[0]
				
				vm.scheduling_data.procedimentos     	= journey.schedulings.map(scheduling => {
					return scheduling.proceeding
				})

				if (vm.scheduling_data.agenda_continua || vm.scheduling_data.encaixe) {
					vm.scheduling_data.data          	= journey.schedulings[0].created_at ? journey.schedulings[0].created_at : moment()
					vm.scheduling_data.hora          	= journey.schedulings[0].created_at ? journey.schedulings[0].created_at : moment()
				} else {
					vm.scheduling_data.data          	= journey.schedulings[0].data
					vm.scheduling_data.hora          	= journey.schedulings[0].hora
				}

				this.$emit('update-indicator', vm.scheduling_data.indicator_doctor)
			},
	        async GetSchedulingData()
	        {
	            const vm            = this

	            if(vm.journey_id) {
	            	vm.StartLoading()

	                return await window.api.call('post', '/get-scheduling-data', {
	                        account_id: vm.requester_selected_id,
	                        journey: 	vm.journey_id
	                    })
		                .then(({data}) => {

			                if (data.response)
			                {
			                    vm.PopulateSchedulingDataAndPatient(data.journey)
			                }else{

			                    Swal.fire({
			                        icon:   'error',
			                        text:   'Houve um erro ao obter, contate o suporte.',
			                        title:  'Opss...'
			                    })

			                }
		                })
		                .finally(() => {
		                	vm.FinishLoading()
		                })
	            } else {

	                Swal.fire({
	                    icon: 'error',
	                    title: 'Dados não informados',
	                    text: 'São necessários os dados para a impressão do comprovante'
	                })
	            }
	        },
			DelayFillObs() {
				const vm = this
				
				vm.scheduling_data.observacao = vm.form.obs

				vm.$emit('update-obs', vm.scheduling_data.observacao)
			}
		}
	}
</script>

<style lang="css" scoped>
	.row {
		gap: 0px 10px;
	}

	.separate-cards {
		margin-top: 4rem;
	}

	.h4-size {
		font-size: 1.4rem;
	}

	.tab-1 {
		tab-size: 2;
	}

	textarea {
		resize: none
	}

	.patient-avatar {
		height: 80px;
	}

	@media (min-width: 768px) {
		.patient-avatar {
			height: 160px;
		}
	}
</style>