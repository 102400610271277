<template>
	<div>
		<div class="container">
			<div class="row align-items-center justify-content-center flex-column">
				<div class="col-12">
					<div class="d-block mx-auto mb-5 pb-md-5 text-md-center max-500">
						<span>{{ label }}</span>
						<template v-if="type == 'scheduling'">
							<h4 class="font-24 font-md-32 m-0">Escolha o horário do agendamento</h4>
						</template>
						<template v-else-if="type == 'fit'">
							<h4 class="font-24 font-md-32 m-0">Escolha a agenda de encaixe</h4>
						</template>
					</div>
				</div>		
				<div class="col-12 col-md-6">

					<div class="row justify-content-between align-items-center mb-4 mb-md-3">
						<div class="col-auto pr-0">
							<div class="d-flex align-items-end">
								<template v-if="type == 'scheduling'">
									<button v-if="today_is_first_day"
										class="btn btn-light btn-sm disabled"
										v-tippy="{ arrow: 'top', content: 'O dia selecionado é o primeiro dia desta agenda.' }"
									>
										<i class="far fa-chevron-left"></i>
									</button>
									<button v-else
										@click="PreviousDay"
										class="btn btn-light btn-sm"
									>
										<i class="far fa-chevron-left"></i>
									</button>
									<div class="text-center mx-3 mx-md-4">
										<small>dia</small>
										<h4 class="font-18 font-md-20">{{ FormatDate(days[position])}}</h4>
									</div>
									<button	v-if="today_is_last_day"
										class="btn btn-light btn-sm disabled"
										v-tippy="GetDayTooltipText()"
									>
										<i class="far fa-chevron-right"></i>
									</button>
									<button v-else
										class="btn btn-light btn-sm"
										@click="NextDay"
									>
										<i class="far fa-chevron-right"></i>
									</button>
								</template>						
							</div>
						</div>
						<div class="col-auto pl-0" v-if="type != 'fit'">					
							<button 
								class="btn btn-light-secondary" 
								data-toggle="modal" 
								data-target="#modal-filter"
							>
								filtrar
							</button>
						</div>				
					</div>

					<div class="card bg-light border-no overflow-hidden">
						<div class="card-body p-3 p-md-5 scroll-this">
							<template v-if="type == 'scheduling'">
								<template v-if="days[position] && times[days[position]].length > 0">
									<div
										v-for="time in customSort(times[days[position]])"
										:key="time.id"
										@click="Continue(time)"
									>
										<div 
											v-if="time.tipo === 1"
											class="select-card with-border mt-3"
										>
											<label>
												{{ time.hora.slice(0,5) }} {{ time.schedule.nome }}
												<i class="far fa-chevron-right"></i>
											</label>
										</div>
										<div 
											v-else-if="time.tipo == 3"
											class="select-card select-blue with-border mt-3"
										>
											<label>
												ESPERA - {{ time.schedule.nome }}
												<i class="far fa-chevron-right"></i>
											</label>
										</div>
									</div>
								</template>
								<template v-else>
									<div class="d-flex justify-content-center">
										<span>Não há horários disponíveis.</span>
									</div>
								</template>
							</template>
							<template v-else-if="type == 'fit'">
								<div
									v-for="fit in fits"
									:key="fit.id"
									@click="Continue(fit)"
								>
									<div class="select-card select-orange with-border mt-3">
										<label>
											ENCAIXE - {{ fit.nome }}
											<i class="far fa-chevron-right"></i>
										</label>
									</div>
								</div>
							</template>
						</div>
					</div>

				</div>		
				<div class="col-12 col-md-6 text-center py-5">
					<div class="d-flex justify-content-between">
						<button 
							class="btn btn-light px-md-5"
							@click="Previous"
						>
							voltar
						</button>
					</div>
				</div>
			</div>
		</div>


		<div class="modal fade" id="modal-filter" 
			data-backdrop="false"
			data-keyboard="true">
		  	<div class="modal-dialog">
			    <div class="modal-content">
			    	<div class="modal-header px-5 py-4">
			    		<h4 class="font-20 m-0">Filtrar agenda</h4>
				        <button 
				        	type="button" 
				        	class="position-absolute btn btn-light p-3 ps-r-0 ps-t-0 m-3" 
				        	data-dismiss="modal"
				        >
				         	<i class="far fa-times mx-1"></i>
				        </button>
			    	</div>
			    	<hr class="m-0">
			        <div class="modal-body">
			        	<div class="row p-4 justify-content-center">
			        		<div class="col-auto mb-4 px-0">        			
								<div class="btn-group-checkbox">
									<label 
										v-for="(weekday, index) in weekdays"
										:key="index"
										:for="weekday.initials"
									>
										<input 
											type="checkbox" 
											v-model="filters.weekdays"
											:value="weekday.value"
											:id="weekday.initials"
										>
										<div class="btn p-3"
											:class="isNotChecked(weekday.value) ? 'btn-light' : 'btn-primary'">
											{{ weekday.initials }}
										</div>
									</label>

								</div>
			        		</div>

			        		<div class="col-6 col-md-6">
								<div class="dropdown d-block">
									<button class="btn btn-light btn-block" data-toggle="dropdown">
										<i class="far fa-chevron-down font-14 mr-3"></i> periodo
									</button>
									<div class="dropdown-menu px-3 py-0">
										<form>
											<div class="form-check my-3">
												<input 
													type="checkbox"
													v-model="filters.period" 
													id="manha"
													:value="1"
												>
												<label for="manha">
													Manhã (6h - 11h)
												</label>
											</div>
											<div class="form-check my-3">
												<input 
													type="checkbox"
													v-model="filters.period" 
													id="tarde"
													:value="2"
												>
												<label for="tarde">
													Tarde (12h - 18h)
												</label>
											</div>
											<div class="form-check my-3">
												<input 
													type="checkbox"
													v-model="filters.period" 
													id="noite"
													:value="3"
												>
												<label for="noite">
													Noite (19h - 5h)
												</label>
											</div>
										</form>
									</div>
								</div>
			        		</div>

			        		<div class="col-6 col-md-6">
								<div class="dropdown d-block">
									<button class="btn btn-light btn-block" data-toggle="dropdown">
										<i class="far fa-chevron-down font-14 mr-3"></i> data
									</button>
									<div class="dropdown-menu px-3 py-0">
										<form>
											<div class="form-group my-3">
												<label class="font-14 mb-1">do dia</label>
												<input 
													type="date" 
													class="form-control p-3" 
													v-model="filters.date_init">
											</div>
											<div class="form-group my-3">
												<label class="font-14 mb-1">até o dia</label>
												<input 
													type="date" 
													class="form-control p-3" 
													v-model="filters.date_end">
											</div>
										</form>
									</div>
								</div>
							</div>

			        		<div class="col-12 mt-4">
								<div class="dropdown d-block">
									<button class="btn btn-light btn-block" data-toggle="dropdown">
										<i class="far fa-chevron-down font-14 mr-3"></i> Agendas utilizadas
									</button>
									<div class="dropdown-menu px-3 py-0">
										<form>
											<div 
												v-for="(schedule, index) in schedules"
												:key="index"
												class="form-check my-3"
											>
												<input 
													type="checkbox"
													v-model="filters.schedules"
													:value="schedule"
													:id="schedule.nome + index"
												>
												<label :for="schedule.nome + index">
													{{ schedule.nome }}
												</label>
											</div>
										</form>
									</div>
								</div>
							</div>
			        	</div>

			    		<hr class="m-0">
			    		<div class="d-flex justify-content-end p-4">
							<button 
								class="btn btn-primary p-3 px-4"
								data-dismiss="modal"
								@click="GetVacancySchedules()"
							>
								aplicar
							</button>
						</div>
					</div>
			    </div>
	  		</div>
		</div>
	</div>
</template>

<script>
	const moment 		= require('moment')
	moment.locale('pt')

	const _ 			= require('lodash')

	import Swal from 'sweetalert2'
	import { mapActions, mapGetters } from 'vuex'
	export default {

		name: 'Schedules',
		props: 	{
			label: 			{
				type: 			String,
				default: 		''
			},
			modality_id: 	{
				type: 			[String, Number],
				default: 		null
			},
			proceedings: 	{
				type: 			Array,
				default: 		() => []
			},
			schedules: 		{
				type: 			Array,
				default: 		() => []
			},
			type: 			{
				type: 			String,
				default: 		'scheduling'
			},
			is_vacancy:		{
				type:			Boolean,
				default:		false
			}
		},
		data () {
			return {
				interval: 	15,
				filters: 	{
					weekdays: 	[],
					period: 	[1, 2, 3],
					date_init:  '',
					date_end: 	'',
					schedules:	[]
				},
				days: 		[],
				times: 		[],
				fits: 		[],
				position: 	0,
				weekdays: 	window.weekDays
			}
		},
		watch: {
			proceedings: {
				handler(val)
				{
					this.GetVacancySchedules()
				},
				deep: true
			},
			schedules(val)
			{
				this.GetVacancySchedules()
			}
		},
		computed: 	{
			...mapGetters('auth', [
				'requester_selected_id'
			]),
			today_is_first_day() {
				return this.position === 0	
			},
			today_is_last_day() {
				return !this.days.length || this.position == (this.days.length - 1)
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			GetVacancySchedules() {	
				if(this.type == 'fit') {
					this.fits 		= [...this.schedules]
				} else if (this.type == 'scheduling') {
					this.StartLoading()

					let obj 		= {
							account_id: 		this.requester_selected_id,
							modality_id: 		this.modality_id,
							proceedings: 		this.proceedings,
							schedules: 			this.filters.schedules.length > 0 ? this.filters.schedules : this.schedules
						}
				
					obj 		= {
						...obj,
						filters: 	this.filters
					}
					
					window.api.call('post', '/get-vacancy-schedules', obj)	
						.then(({data}) => {
							if(data.response) {
								let days 				= Object.keys(data.days)

								this.days 				= _.sortBy(days, (o) => new Date(o))

								this.times 				= data.days
							} else {
								Swal.fire({
									title: 	'Eita !',
									text: 'Houve um erro ao obter, contate o suporte',
									icon: 'error'
								})
							}	
						})
						.finally(() => {
							this.FinishLoading()
						})
				}
			},
			ResetFilters()
			{
				this.filters.weekdays 		= [0, 1, 2, 3, 4, 5, 6]
				this.filters.period 		= [1, 2, 3]
				this.filters.date_init 		= moment().format('YYYY-MM-DD')
				this.filters.date_end 		= moment().add(this.interval,'day').format('YYYY-MM-DD')
			},			
			PreviousDay()
			{
				this.position 		= this.days[(this.position - 1)] ? this.position - 1 : 0
			},
			async NextDay() {
				if(this.today_is_last_day) return;

				if((this.position + 1) === this.days.length) {					
					this.filters.date_end 		= moment(this.filters.date_end).add(this.interval,'day').format('YYYY-MM-DD')

					await this.GetVacancySchedules()

					this.position 				= this.days[(this.position + 1)] ? this.position + 1 : this.position
				} else {
					this.position 				= this.days[(this.position + 1)] ? this.position + 1 : this.position
				}
			},
			FormatDate(date)
			{
				return window.helpers.FormatDate(date)
			},
			Previous()
			{
				this.$emit('previous')
			},
			Continue(time)
			{
				this.$emit('next', time)
			},
			customSort(times)
			{
				return _.sortBy(times, ['dia', 'hora'])
			},
			isNotChecked(value)
			{
				return this.filters.weekdays.indexOf(value) === -1
			},
			CloseModal(modal_id) {
				window.jQuery(modal_id).modal('hide')
			},
			GetDayTooltipText() {
				let content = ''

				if(!this.days.length) {
					content = 'Não há dias configurados na agenda.'
				}

				if(this.position == (this.days.length - 1)) {
					content = 'O dia selecionado é o último dia desta agenda.'
				}

				return {
					arrow: true,
					hideOnClick: false,
					content,
				}
			}
		},
		mounted() {
			this.filters.date_end 		= moment().add(this.interval,'day').format('YYYY-MM-DD')

			this.GetVacancySchedules()

			document.addEventListener('keyup', e => {
				if(e.keyCode === 27) {
					this.CloseModal('#modal-filter')
				}
			})
		}
	}
</script>

<style lang="css" scoped>
.disabled {
	opacity: .5;
	cursor: not-allowed;
}

.disabled:hover,
.disabled:active,
.disabled:focus {
	color: var(--gray-light) !important;
}
</style>