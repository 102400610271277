<template>
	<div 
		:class="{
			'container-large': !carousel
		}"
	>
		<!--<div class="pt-2 text-center" v-if="!carousel">
			<h4 class="font-40">Bem-vindo!</h4>
			<p>Selecione uma ação para iniciar</p>
		</div>-->
		<div class="row pb-5 justify-content-start">
			<Menu :carousel="carousel"/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
	import Menu from './Menu'
	export default {
		components: {
			Menu,
		},
		name: 'Index',
		props: 			{
			carousel: 	{
				type: 		Boolean,
				default: 	false
			}
		},
		data () {
			return {

			}
		},
		computed: {
			...mapGetters('auth', [
				'requester_selected_id', 'requesters'
			]),
			is_confirmed() {
				const is_confirmed = this.requesters.find(r => r.account_requester_id == this.requester_selected_id)?.confirmed ?? false

				if(this.requester_selected_id && !is_confirmed) {
					this.$router.push({ name: 'Organizations' })
				}
				
				return is_confirmed
			}
		},
		watch: {
			is_confirmed: {
				handler(val) {
					if(this.requester_selected_id && !val) {
						this.$router.push({ name: 'Organizations' })
					}
				}
			}
		},
		mounted() {
		}
	}
</script>

<style>

</style>