<template>
	<div 
		class="modal fade" 
		id="payment-methods-modal"
	>
		<div class="modal-dialog" style="max-width: 600px">
			<div class="modal-content">
				<div class="modal-header px-5 py-4 mb-3">
					<h4 class="font-20 m-0 text-center">
						Adicionar forma de pagamento
					</h4>
					<button 
						type="button" 
						class="position-absolute btn btn-light p-3 ps-r-0 ps-t-0 m-3"
						data-dismiss="modal"
					>
						<i class="far fa-times mx-1"></i>
					</button>
				</div>
				<div class="modal-body">
					<PaymentMethods 
						ref="payment_methods"
						:total="total"
						@payment-methods-added="payment_methods = $event"
					/>
				</div>
				<div class="modal-footer mt-2">
					<button 
						id="finish"
						type="button"
						class="btn btn-primary p-3 px-4"
						@click="CloseModal()"
					>	
						continuar
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PaymentMethods from './PaymentMethods.vue'

export default {
	name: 'PaymentMethodsModal',
	components: {
		PaymentMethods
	},
	props: {
		total: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			payment_methods: null
		}
	},
	methods: {
		async CloseModal() {
			if(!await this.$refs.payment_methods.AddCurrentMethod()) {
				return;
			}

			this.$emit('payment-methods-added', this.payment_methods)

			window.jQuery('#payment-methods-modal').modal('hide')
			window.jQuery('body').removeClass('modal-open')
		},
	}
}
</script>

<style>

</style>