<template>
    <div class="container-large pt-md-2">
        <div class="col-auto pb-3 pt-4 pt-md-0">
			<div class="row justify-content-between align-items-center px-2"  v-if="!carousel">
				<div class="col-auto"
                    :class="edit ? 'pr-0' : ''">
					<button 
						class="btn mr-3 btn-sm"
						:class="edit ? 'btn-danger' : 'btn-light'"
						@click="EditingMenu"
                        style="border-radius: 16px; width: 60px;" 
                    >
						<i 
                            class="far"
							:class="edit ? 'fa-times fa-lg' : 'fa-pencil-alt'"
                        ></i>
					</button>
					<button
						v-if="edit" 
						class="btn btn-sm btn-success"
						@click="SaveCustom(custom_menu)"
                        style="border-radius: 16px; width: 60px;"
                    >
						<i class="fal fa-check fa-lg"></i>
					</button>
				</div>
                <div class="col-auto">
    				<div class="btn-group-checkbox d-inline-block align-middle">
    					<label for="small">
    						<input 
    							type="radio"
    							id="small"
    							value="sm"
    							v-model="tmp_size"
                                @click="SetIconsSize('sm')"
    						>
    						<div class="btn btn-light btn-sm" style="padding: 11px 15px;">
    							<b class="align-text-bottom" style="font-size: .7rem;">A</b>
    						</div>
    					</label>
    					<label for="medium">
    						<input 
    							type="radio" 
    							name="tipo" 
    							id="medium"
    							value="md"
    							v-model="tmp_size"
                                @click="SetIconsSize('md')"
    						>
    						<div class="btn btn-light btn-sm" style="padding: 9px 13px;">
    							<b class="align-text-bottom" style="font-size: 1.05rem;">A</b>
    						</div>
    					</label>
    					<label for="large">
    						<input 
    							type="radio" 
    							name="tipo" 
    							id="large"
    							value="xl"
    							v-model="tmp_size"
                                @click="SetIconsSize('xl')"
    						>
    						<div class="btn btn-light btn-sm" style="padding: 6px 13px;">
    							<b class="align-text-bottom" style="font-size: 1.3rem;">A</b>
    						</div>
    					</label>
    				</div>
                </div>
			</div>
		</div>
        <div v-if="!edit" class="form-row pb-5 justify-content-start">
            <div 
                v-for="menu in filtered_menu"
                :key="menu.role"
                class="mb-2 mt-1 position-relative"
                :class="{
                    'col-12 col-md-6 col-lg-4 col-xl-3': icon_size == 'xl',
                    'col-6 col-md-4 col-lg-3 col-xl-2': icon_size == 'md',
                    'col-6 col-md-3 col-lg-2 col-xl-auto': icon_size == 'sm'
                }"
            >
                    <div v-if="menu.text == 'Organizações' && requesters_unconfirmed"
                        class="position-absolute px-2 unconfirmed">{{ requesters_unconfirmed }}
                    </div>
                <router-link 
					v-if="!DoesntHavePermission(menu.role)"
                    class="box w-100 icons-card"
                    :class="{
                        'opacity-50': menu.role && !menu.force ? DoesntHavePermission(menu.role) : false,
                        'primary-hover' : menu.role && !menu.force ? !DoesntHavePermission(menu.role) : true,
                        'mw-135px rounded-md': icon_size == 'sm'
                    }"
                    :to="GetRoute(menu)"
                >
                    <div class="card-body text-center position-absolute ps-0 d-flex align-items-center justify-content-center">
                        <div class="form-row h-100">
                            <div class="col-12 align-self-center">
                                <i 
                                    :class="`${menu.icon} fal font-32 ${ icon_size != 'sm' ? 'font-sm-40' : ''}  mt-3 text-theme`"	
                                >								
                                </i>
                            </div>
                            <div class="col-12 align-self-start">
                                <h5 
                                    class="m-0 font-16" 
                                    :style="(icon_size == 'sm' ? 'font-weight: 500;' : '')"
                                >
                                    {{ menu.text }}
                                </h5>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <draggable v-if="edit" v-model="custom_menu">
            <transition-group 
                name="flip-list"
                tag="div"
                class="form-row pb-5 justify-content-start"
            >
                    <div 
                        v-for="menu in filtered_menu"
                        :key="menu.role"
                        class="mb-2 mt-1 sortable"
                        :class="{
                            'col-12 col-md-6 col-lg-4 col-xl-3': icon_size == 'xl',
                            'col-6 col-md-4 col-lg-3 col-xl-2': icon_size == 'md',
                            'col-6 col-md-3 col-lg-2 col-xl-auto': icon_size == 'sm'
                        }"
                    >
                        <router-link 
                            class="box w-100 icons-card"
                            :class="{
                                'opacity-50': menu.role && !menu.force ? DoesntHavePermission(menu.role) : false,
                                'primary-hover' : menu.role && !menu.force ? !DoesntHavePermission(menu.role) : true,
                                'mw-135px rounded-md': icon_size == 'sm'
                            }"
                            :to="GetRoute(menu)"
                        >
                            <div class="card-body text-center position-absolute ps-0 d-flex align-items-center justify-content-center">
                                <div class="form-row h-100">
                                    <div class="col-12 align-self-center">
                                        <i 
                                            :class="`${menu.icon} fal font-32 ${ icon_size != 'sm' ? 'font-sm-40' : ''}  mt-3 text-theme`"    
                                        >                               
                                        </i>
                                    </div>
                                    <div class="col-12 align-self-start">
                                        <h5 
                                            class="m-0 font-16" 
                                            :style="(icon_size == 'sm' ? 'font-weight: 500;' : '')"
                                        >
                                            {{ menu.text }}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </div>
            </transition-group>
        </draggable>
    </div>
</template>

<script>
    import draggable from 'vuedraggable'
    import { mapGetters, mapActions } from 'vuex'
	import { is_registered } from '../../store/auth/getters'
    export default {
        components: {
            draggable
        },
        props: {
            carousel: {
                type:       Boolean,
                default:    false
            }
        },
        data() {
            return {
                tmp_size:           'md',
                custom_menu:        [],
                edit:               false,
            }
        },
        computed: {
            ...mapGetters('system', [
                'icon_size', 'menu_order'
            ]),
            ...mapGetters('auth', [
				'roles', 'requester_selected_object', 'requesters', 'is_registered', 'subscription_info'
            ]),
			requesters_unconfirmed()
			{
				let unconfirmeds = this.requesters.filter(r => {
					return typeof r.confirmed !== 'undefined' && !r.confirmed
				}).length

                return unconfirmeds > 9 ? '9+' : unconfirmeds
			},
			filtered_menu() {
				return this.custom_menu.filter((m) => !this.DoesntHavePermission(m.role))
			}
        },
        watch: {
			icon_size: {
				handler(val)
				{
					this.tmp_size 	= val
				},
				immediate: true
            },
		},
        methods: {
            ...mapActions('system', [
				'SetIconsSize', 'SetMenuOrder'
			]),
			GetRoute(menu)
			{
				return { name: menu.route }
			},
			DoesntHavePermission(role)
			{
                const requester = this.requester_selected_object

				if(requester) {
					if(this.is_registered) {
						const subscription_info = this.subscription_info

						if(subscription_info && (subscription_info && subscription_info.days_left <= 0) && (subscription_info.hours_left && subscription_info.hours_left <= 0)) {
							// return true
						}
					}

					const pf = requester.account_provider_id === requester.account_requester_id

					if(requester && requester.confirmed || pf) {
                        return this.roles.indexOf(parseInt(role)) < 0
                    }
                }

                return true
            },
            EditingMenu()
			{
                this.edit = !this.edit

                // Cancel
				if(!this.edit) 
                {
                    this.custom_menu = this.menu_order
				}
			},
			SaveCustom(val)
			{
                this.edit = false

                const menu_ordered = val.map((m, i) => {
                    return { 
                        order: i + 1,
                        id: m.id
                    }
                })
                
                this.SetMenuOrder({
                    menu_mapped: menu_ordered,
                    custom_menu: val
                })
			}
        },
        beforeMount() 
        {
            this.custom_menu = this.menu_order
        }
    }
</script>

<style lang="css" scoped>
    .mw-135px
	{
		min-width: 135px;
	}

	h5
	{
		white-space: break-spaces;
	}

    .flip-list-move {
        transition: transform .3s;
    }

    .rounded-md 
    {
        border-radius: 1.4rem !important;
    }

    .unconfirmed {
        background: linear-gradient(0deg, rgb(214, 1, 16) 0%, rgb(219, 34, 34) 100%);
        color: #fff;
        font-weight: bold;
        right: -5px;
        top: -5px;
        border-radius: 50%;
        border: 1px solid rgb(219, 34, 34);
    }
</style>