<template>
	<div class="d-flex flex-column align-items-center">
		<div class="col-12 col-md-8 px-md-0 d-flex align-items-center form-group">
			<label class="col-4 col-md-3 px-0 mr-3 font-weight-bold">Valor:</label>
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">R$</span>
				</div>
				<MoneyInput
					custom_class="col px-3 font-18 form-control"
					:value="total"
					prefix=""
					disabled
				/>
			</div>
		</div>
		<div class="col-12 col-md-8 px-md-0 d-flex align-items-center form-group">
			<label class="col-4 col-md-3 px-0 mr-3 font-weight-bold">Descontos:</label>
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">R$</span>
				</div>
				<MoneyInput
					custom_class="col px-3 font-18 form-control"
					v-model="discount"
					prefix=""
					:max="total"
				/>
			</div>
		</div>
		<div class="col-12 col-md-8 px-md-0 d-flex align-items-center form-group">
			<label class="col-4 col-md-3 px-0 mr-3 font-weight-bold">Valor restante:</label>
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">R$</span>
				</div>
				<MoneyInput
					custom_class="col px-3 font-18 form-control"
					:value="remaining_value"
					prefix=""
					disabled
				/>
			</div>
		</div>
		<div id="add-form-row" class="col-12 d-flex align-items-center form-group my-5">
			<select 
				id="payment-methods"
				class="col form-control"
				name="payment-methods"
				v-model="selected.payment_method_id"
				:disabled="!remaining_value && !selected.value"
			>
				<option :value="null" disabled>Selecione a forma de pagamento</option>
				<option v-for="method in payment_methods"
					:key="`payment-method-${method.id}`"
					:value="method.id"
					:disabled="IsAlreadyAdded(method.id)"
				>
					{{ method.name }}
				</option>
			</select>
			<div class="col d-flex align-items-center">
				<div class="input-group">
					<div class="input-group-prepend"
						v-tippy="{
							content: 'Clique para preencher com o valor restante',
							arrow: 'top'
						}"
						@click="(remaining_value > 0 ? selected.value = selected.value + remaining_value : selected.value = selected.value)"
					>
						<span class="input-group-text">R$</span>
					</div>
					<MoneyInput
						custom_class="col px-3 font-16 form-control"
						:disabled="!remaining_value && !selected.value"
						v-model="selected.value"
						:max="remaining_without_selected"
						prefix=""
					/>
				</div>
			</div>
			<div class="add-button-container">
				<button
					class="btn btn-light-success px-3 py-2"
					@click="AddCurrentMethod"
					:disabled="!remaining_value && !selected.value"
				>
					<i class="far fa-plus font-24"></i>
				</button>
			</div>
		</div>
		<div v-if="selected.methods.length" class="col-12 d-flex flex-column align-items-center">
			<div 
				class="col-12 px-0 d-flex align-items-center form-group"
				v-for="(selected_method, index) in selected.methods"
				:id="`added-method-${index}`"
				:key="`added-method-${index}`"
			>
				<select 
					:id="`added-method-${index}`"
					class="col form-control"
					v-model="selected_method.method_id"
					@change="AddedPaymentMethodChangeHandler(selected_method)"
				>
					<option
						v-for="method in payment_methods"
						:key="`added-method-${index}-${method.id}`"
						:value="method.id"
						:disabled="IsAlreadyAdded(method.id)"
					>
						{{ method.name }}
					</option>
				</select>
				<div class="col d-flex align-items-center">
					<div class="input-group">
						<div class="input-group-prepend">
							<span class="input-group-text">R$</span>
						</div>
						<MoneyInput
							custom_class="col px-3 font-16 form-control"
							v-model="selected_method.value"
							prefix=""
							:max="GetMaxValue(selected_method.value)"
						/>
					</div>
				</div>
				<div class="actions d-flex justify-content-center">
					<i 
						class="fas fa-trash col-auto px-0 text-danger font-24"
						@click="RemovePaymentMethod(index)"
					>
					</i>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import { maxValue } from 'vuelidate/lib/validators'
import MoneyInput from '../../shared/MoneyInput.vue'
import Swal from 'sweetalert2'

export default {
	name: 'PaymentMethods',
	props: {
		total: {
			type: Number,
			default: 0
		},
		events: {
			type: Array
		}
	},
	components: {
		MoneyInput
	},
	data() {
		return {
			payment_methods: [],
			selected: {
				methods: [],
				payment_method_id: null,
				value: 0,
			},
			discount: 0
		}
	},
	computed: {
		total_with_discount() {
			return (this.total - this.discount) || 0
		},
		added_total() {
			let value = 0

			if(!this.selected.methods.length) return value

			value = this.selected.methods.map(m => m.value).reduce((acc, curr) => acc + curr)

			return Number((value).toFixed(2))
		},
		remaining_without_selected() {
			let value = 0
			
			value = this.total_with_discount - this.added_total

			if(value < 0) value = 0

			return Number((value).toFixed(2))
		},
		
		remaining_value() {
			let value = 0

			value = this.remaining_without_selected - this.selected.value

			if(value < 0) value = 0

			return Number((value).toFixed(2))
		}
	},
	watch: {
		discount: {
			handler(val) {
				this.$emit('payment-methods-added', {
					total: this.total,
					discount: val,
					methods: [ ...this.selected.methods ]
				})
			}
		},
		'selected.methods': {
			handler(val) {
				this.$emit('payment-methods-added', {
					total: this.total,
					discount: this.discount,
					methods: [ ...val ]
				})
			},
			deep: true
		},
	},
	methods: {
		...mapActions('system', [
			'StartLoading', 'FinishLoading'
		]),
		GetMaxValue(selected_value) {
			let max = Math.abs(this.remaining_value + selected_value)

			max = max > this.total ? this.total : max

			return Number(max.toFixed(2))
		},
		IsAlreadyAdded(method_id) {
			return !!this.selected.methods.find(m => m.method_id == method_id)
		},
		GetPaymentMethods() {
			this.StartLoading()

			return window.api.call('get', '/get-payment-methods')
				.then(({data}) => {
					if(data.response) {
						this.payment_methods = data.payment_methods
					}
				})
				.finally(() => {
					this.FinishLoading()
				})
		},
		AddCurrentMethod() {
			if(!this.remaining_without_selected) {
				return true;
			}

			if(!this.selected.payment_method_id) {
				Swal.fire({
					icon: 'error',
					title: 'Erro!',
					html: '<p>Erro ao adicionar forma de pagamento! Adicione uma <b>forma de pagamento</b> para continuar.</p>'
				})
				
				return false;
			}

			if(!this.selected.value) {
				Swal.fire({
					icon: 'error',
					title: 'Erro!',
					html: '<p>Erro ao adicionar forma de pagamento! Adicione um <b>valor</b> para continuar.</p>'
				})
				
				return false;
			}

			if(this.selected.value > this.remaining_without_selected) this.selected.value = this.remaining_without_selected

			this.selected.methods.push({ 
				method_id: this.selected.payment_method_id,
				method_name: this.GetPaymentMethodById(this.selected.payment_method_id),
				value: this.selected.value,
			})

			this.selected.payment_method_id = null
			this.selected.value = 0

			return true;
		},
		GetPaymentMethodById(id) {
			return this.payment_methods.find(p => p.id == id)?.name ?? ''
		},
		RemovePaymentMethod(index) {
			this.selected.methods = this.selected.methods.filter((_, i) => i != index)
		},
		AddedPaymentMethodChangeHandler(selected_method) {
			selected_method.payment_method_name = this.GetPaymentMethodById(selected_method.payment_method_id)

			this.selected.payment_method_id = null
		},
	},
	mounted() {
		this.GetPaymentMethods()
	}
}
</script>

<style lang="scss" scoped>
#add-form-row .input-group .input-group-prepend {
	cursor: pointer;
}

.add-button-container,
.actions {
	width: 43.6px;
}

.actions i {
	&.text-success:hover {
		color: #1b6f3d !important;
	}

	&.text-warning:hover {
		color: #9d7b14 !important;
	}

	&.text-danger:hover {
		color: #7f1923 !important;
	}

	cursor: pointer;
}

</style>