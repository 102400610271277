<template>
	<input
		ref="input"
		type="text"
		:class="custom_class"
		v-model="inputVal"
		@keyup="KeyUpEventHandler"
		@keydown="KeyDownEventHandler"
		@focus="$emit('focus', $event)"
		@blur="$emit('blur', $event)"
		v-bind="$attrs"
	/>
</template>

<script>
export default {
	inheritAttrs: false,
	name: "MoneyInput",
	props: {
		value: {
			type: [String, Number],
			required: true,
			default: 0,
		},
		custom_class: {
			type: String,
			default: "",
		},
		min: {
			type: [String, Number],
			default: 0,
		},
		max: {
			type: [String, Number],
			default: null,
		},
		precision: {
			type: [String, Number],
			default: 2,
		},
		prefix: {
			type: String,
			default: "R$ ",
		},
		decimal: {
			type: String,
			default: ",",
		},
		thousand: {
			type: String,
			default: ".",
		},
	},
	data() {
		return {
			current_value: 0,
			free_indicator: false,
		}
	},
	computed: {
		inputVal: {
			get() {
				let new_value = `${this.prefix}0${this.decimal}${'0'.repeat(this.precision)}`

				if(this.value) {
					new_value = this.DefineFormat(this.value.toFixed(this.precision))

					new_value = `${this.prefix}${new_value}`
				}

				return new_value
			},
			set(val) {
				let new_value = val.toString()

				this.$emit("input", Number(this.ParseToDefaultValue(new_value)))
			},
		},
		already_has_thousand() {
			const regex_thousand = new RegExp(`\\d+\\${this.thousand}\\d{3}${this.decimal}\\d{${this.precision}}$`)

			const match = this.value.toString().match(regex_thousand)

			return match?.length > 0
		},
		already_has_decimal() {
			const regex_decimal = new RegExp(`${this.decimal}\\d{${this.precision}}$`)

			const match = this.value.toString().match(regex_decimal)

			return match?.length > 0
		},
		input_selected_range() {
			return Math.abs(this.$refs.input.selectionStart - this.$refs.input.selectionEnd)
		}
	},
	methods: {
		KeyUpEventHandler(event) {
			this.$emit('keyup', event)
		},
		KeyDownEventHandler(event) {
			this.$emit('keydown', event)

			this.current_value = this.SanitizeEventTargetValue(event.target.value);

			this.PreventAddingSomething(event);
			this.PreventBackspaceDefaultEvent(event);
			this.PreventExeptionsKeysEvent(event);
			
			this.SetValueLazy()
		},
		SetValueOnInput() {
			this.$refs.input.value = `${this.prefix}${this.current_value}`

			this.inputVal = `${this.current_value}`
		},
		SetValueLazy() {
			setTimeout(() => {
				this.SetValueOnInput()
			}, 100)

			if(!this.free_indicator) this.SetCursorToEnd()

			this.free_indicator = false
		},
		SetCursorToEnd() {
			const input = this.$refs.input

			input.setSelectionRange(input.value.length, input.value.length)
		},
		SanitizeEventTargetValue(value) {
			return value.replace(this.prefix, '')
		},
		ParseToDefaultValue(value) {
			return value.toString().replaceAll('.', '').replace(',', '.')
		},
		PreventAddingSomething(event) {
			if(event.key.match(/^\d$/g)?.length > 0) {
				event.preventDefault()

				this.AddDigit(event.key)
			}
		},
		PreventExeptionsKeysEvent(event) {
			switch(event.key) {
				case 'F5':
					window.location.reload()
				break;
				case 'Shift':
				case 'ArrowLeft':
				case 'ArrowRight':
					this.free_indicator = true
				break;
				case 'Control':
				case 'c':
					if(event.ctrlKey) {
						this.free_indicator = true
					}
				break;
				default: 
					event.preventDefault()
				break;
			}
		},
		PreventBackspaceDefaultEvent(event) {
			if(event.key == "Backspace") {
				event.preventDefault();

				if(this.IsFullSelection()) {
					this.current_value = '0,00'
					
					return;
				}

				this.RemoveDigit()
			}
		},
		IsFullSelection() {
			let result = true

			const start = this.$refs.input.selectionStart
			const end = this.$refs.input.selectionEnd

			let selection_length = Math.abs(start - end)

			result = selection_length == this.inputVal.length
			
			return result
		},
		AddDigit(digit) {
			this.current_value = this.current_value.replace(`${this.prefix}`, '')

			if(this.current_value[0] == 0) {
				this.current_value = this.current_value.substring(1)
			}

			this.current_value = this.current_value + digit

			this.HandleFormat()

			// this.HandleMin()
			this.HandleMax()
		},
		RemoveDigit(amount_to_remove = 1) {
			for (let i = 0; i < amount_to_remove; i++) {
				this.current_value = this.current_value.replace(`${this.prefix}`, '')
	
				this.current_value = this.current_value.substring(0, this.current_value.length - 1)
	
				this.HandleFormat()
	
				// this.HandleMin()
			}
		},
		HandleMin() {
			if(!this.min) return
			
			const min = Number(this.min)
			const value = Number(this.ParseToDefaultValue(this.current_value))

			if(value < min) {
				this.current_value = this.min.toFixed(this.precision)
			}

			this.HandleFormat()
		},
		HandleMax() {
			if(!this.max) return

			const max = Number(this.max)
			const value = Number(this.ParseToDefaultValue(this.current_value))

			if(value > max) {
				this.current_value = this.max.toFixed(this.precision)
			}

			this.HandleFormat()
		},
		async HandleFormat() {
			await this.HandleDecimal()
			await this.HandleThousand()
		},
		HandleThousand() {
			this.current_value = this.DefineThousand(this.current_value)
		},
		HandleDecimal() {
			this.current_value = this.DefineDecimal(this.current_value)
		},
		DefineFormat(value) {
			value = this.DefineDecimal(value)
			value = this.DefineThousand(value)

			return value
		},
		DefineDecimal(value) {
			const default_length = parseInt(this.precision) + 2

			value = value.toString().replaceAll(this.thousand, '')

			if(value.indexOf(this.decimal) == -1 || value.length < default_length) {
				value = value.replace(this.decimal, '').padStart(default_length - 1, '0')
			}
			
			value = value.replace(this.decimal, '')
			value = value.split('')
			value.splice((value.length - this.precision), 0, this.decimal)
			value = value.join('')

			return value
		},
		DefineThousand(value) {
			if(!this.thousand) return;

			const thousand_minimal_length = 7

			if(value.length >= thousand_minimal_length) {
				value = value.replaceAll(this.thousand, '')

				let [thousands_string, decimal_value] = value.split(this.decimal)

				const thousands = this.DivideThousandsBlocks(thousands_string)

				if(!thousands.length) return value

				value = thousands.join(this.thousand)
				const decimal = `${this.decimal}${decimal_value}`

				value = `${value}${decimal}`
			}

			return value
		},
		DivideThousandsBlocks(pre_thousand_number) {
			const reversedStr = pre_thousand_number.split('').reverse().join('');

			const reversedMatch = reversedStr.match(/\d{1,3}/g);

			const result = reversedMatch.map(part => part.split('').reverse().join('')).reverse();

			return result
		},
	},
	mounted() {
		if(this.min && this.min < 0) return
		
		this.$refs.input.focus();

		if(this.min) {
			this.current_value = this.min.toFixed(this.precision)

			this.HandleFormat()
			this.SetValueLazy()
		}
	},
}
</script>

<style lang="css" scoped>
</style>
