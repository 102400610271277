import { render, staticRenderFns } from "./PaymentMethods.vue?vue&type=template&id=33d90e63&scoped=true"
import script from "./PaymentMethods.vue?vue&type=script&lang=js"
export * from "./PaymentMethods.vue?vue&type=script&lang=js"
import style0 from "./PaymentMethods.vue?vue&type=style&index=0&id=33d90e63&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33d90e63",
  null
  
)

export default component.exports